// Modified by: Mrunal Mane
// On: 2024-05-10
// Reason: Removed language dependancy from useEffect initial load

import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link } from 'react-router-dom';
import withRouter from "hoc/withRouter";
import { useNavigate, useLocation, useParams } from 'react-router-dom';


// Redux Imports
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { withReducer } from "store/withReducer";
import { CommonComponentState, EventSourceState, StatusFilterState, LanguageState } from "store/Reducers";
import * as ActionTypes from "store/Actions/ActionTypes";
import { UserEngagementDetailState } from "store/Reducers/GuideInsights/userEngagementDetail";
import userEngagementDetailReducer from "store/Reducers/GuideInsights/userEngagementDetail";
import * as userEngagementDetailActions from "store/Actions/index";
import { getCallExportExcel, getCall } from "utils/ApiCallActions";
import EnvironmentFilter from "components/EnvironmentFilter/EnvironmentFilter";

import { ChartColors, RetryApi } from "utils/GlobalConstants";
import { CommonUtils } from "utils/CommonUtils";
import qs from 'querystring';


// SCSS Imports
import "./UserEngagementDetail.scss";


import Loader from "components/Loader/Loader";
import SectionHeader from 'components/Layouts/SectionHeader';
import Datepicker from 'components/Calender/Datepicker';
import SectionButtons from "components/Layouts/SectionButtons";
import ExportToPdf from "components/ExportToPdf/ExportToPdf";
import Linechart from "components/Chart/Linechart";
import SectionTooltip from "components/SectionTooltip/SectionTooltip";
import SectionRefresh from "components/Layouts/SectionRefresh";
import CardContainer from "components/Layouts/CardContainer";
import ContainerTooltip from "components/Layouts/ContainerTooltip";
import CommonTable from "components/Table/CommonTable";
import SearchDropdown from "components/SearchDropdown/SearchDropdown";
// import Piechart from "components/Chart/Piechart";
import Piechart from "components/Chart/nvd3Charts/Piechart";
import Card from "components/Card/Card";
import StateFilter from "components/StateFilter/StateFilter";
import Maintenance from "components/Maintenance/Maintenance";
import LineChart from "components/Chart/nvd3Charts/LineChart";
import { useErrorBoundary } from 'react-error-boundary';

import { getSelectedLabels } from "utils/stringConstants/trans_utils";
import SectionFilters from "components/Layouts/SectionFilters";
import { InsightLabels } from "labels";
import UserInfoSection from "components/Layouts/UserInfoSection";
import { Dropdown, ButtonGroup, Button } from "react-bootstrap";


interface CurrentState {
    userEngagementDetails: UserEngagementDetailState,
    statusFilter: StatusFilterState,
    eventSource: EventSourceState,
    commonComponentData: CommonComponentState,
    languageData: LanguageState
}

const UserEngagementDetail: React.FC = (props: any) => {

    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels);
    const { showBoundary } = useErrorBoundary();


    // CommonUtils.LeftPanelHighlight(1, 2, 0, false, false);
    const location = useLocation();
    const navigate = useNavigate();
    const componentRef = useRef() as React.RefObject<HTMLDivElement>;

    const dateZone: any = new Date().toString();
    const timeZone: string = dateZone.match(/([A-Z]+[+-][0-9]+)/)[1];
    let urlData: any = {};
    const query = new URLSearchParams(location.search);
    query.forEach((v, k) => {
        urlData[k] = v;
    });

    const { unique_user_id } = useParams();
    const userEngagementId = unique_user_id;

    const dispatch = useDispatch();

    let fetched_details = useSelector((state: CurrentState) => state);

    let lineChartMargin = {
        top: "50",
        right: "50",
        bottom: "50",
        left: "65",
    };

    let linechart_height = 400;
    let guide_linechart_height = 350;

    const pie_margin = {
        top: 10,
        bottom: 5,
        left: 0,
        right: 0
    }

    const SummaryMount = useRef(false);
    const ListMount = useRef(false);
    const GuideMeMount = useRef(false);
    const ShowMeMount = useRef(false);
    const TestMeMount = useRef(false);
    const DoItForMeMount = useRef(false);
    const TutorialGuidesMount = useRef(false);
    const TimeLineMount = useRef(false);

    const [errorCount, setErrorCount] = useState({
        errorCount: [0, 0, 0, 0, 0, 0, 0, 0, 0],
        showMaintenance_flag: false
    });

    const [loaderBtn, setLoaderBtn] = useState({
        loader0: false,
        loader1: false,
        disabled: true,
    });

    const [userEngagementDetailSummaryTable, setUserEngagementDetailSummaryTable] = useState({
        userEngagementDetailSummaryTableDataFetched: false,
        searchQuery: '',
        sortKey: 'guide_name',
        filterLabels: [],
        page: 1,
        showExport: true,
        defaultSort: {
            'sortColumn_index': 0,
            'sortValue': 'asc'
        },
        sortValue: 'asc',
        limit: 5,
        tablePagination: {
            offset: 0,
            data: [],
            perPage: 5,
            currentPage: 0
        },
        firstLoad: true,
        errorMsg: ''
    });

    const [listData, setListData] = useState({
        guidesData: [],
        guideDataFetched: false,
        errorMsg: ''
    });

    const [userEngagementGuideMeChart, setUserEngagementGuideMeChart] = useState({
        loadGuideMeChartData: [],
        loadGuideMeChartOptions: [],
        userEngagementGuideMeChartDataFetched: false,
        errorMsg: ''
    });

    const [userEngagementShowMeChart, setUserEngagementShowMeChart] = useState({
        loadShowMeChartData: [],
        loadShowMeChartOptions: [],
        userEngagementShowMeChartDataFetched: false,
        showMeDonutChartData: [],
        showMePieChartData: [],
        showMeLineChartData: [],
        showMeDonutDataAvailable: false,
        showMePieDataAvailable: false,
        errorMsg: ''
    });

    const [userEngagementTestMeChart, setUserEngagementTestMeChart] = useState({
        loadTestMeChartData: [],
        loadTestMeChartOptions: [],
        userEngagementTestMeChartDataFetched: false,
        errorMsg: ''
    });

    const [userEngagementDoItForMeChart, setUserEngagementDoItForMeChart] = useState({
        loadDoItForMeChartData: [],
        loadDoItForMeChartOptions: [],
        userEngagementDoItForMeChartDataFetched: false,
        errorMsg: ''
    });

    const [userEngagementTutorialGuidesChart, setUserEngagementTutorialGuidesChart] = useState({
        loadTutorialGuidesChartData: [],
        loadTutorialGuidesChartOptions: [],
        userEngagementTutorialGuidesChartDataFetched: false,
        errorMsg: ''
    });

    const [guideDetail, setGuideDetail] = useState({
        guideStatus: '',
        guideColor: '',
        guideStatus_flag: false,
        guideName: '',
        isTutorialGuide: null,
        guideID: null,
        errorMsg: ''
    })

    const [timelineData, setTimelineData] = useState({
        dataList: [],
        dataCount: 0,
        dataFetched: false,
        page: 1,
        limit: 5,
        cardPagination: {
            offset: 0,
            data: [],
            perPage: 5,
            currentPage: 0
        },
        firstLoad: true,
        eventType: 'all',
        errorMsg: ''
    })

    const [userName, setUserName] = useState('')

    // First Load @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }

    }, [fetched_details.languageData.languageData])

    useEffect(() => {
        if (insightsLbls !== undefined) {
        }
        CommonUtils.LeftPanelHighlight(1, 6, 6, true, false);
        window.scrollTo(0, 0);
    }, [insightsLbls])

    useEffect(() => {
        let queryParams: any = {
            app_id: fetched_details?.commonComponentData.appsData.appId,
            app_code: fetched_details?.commonComponentData.appsData.appCode,
            fdate: fetched_details?.commonComponentData.datesData.fdate,
            tdate: fetched_details?.commonComponentData.datesData.tdate,
            state: fetched_details?.commonComponentData?.stateFilterData.stateFilter,

        }

        let component_path: any[] = location.pathname.split('/');
        component_path = component_path.filter(function (el) {
            return el != '';
        });

        navigate({
            pathname: "/guide-insights/user-engagement-detail/" + component_path[component_path.length - 1] + '/',
            search: qs.stringify(queryParams),
        });

        if (fetched_details?.commonComponentData.datesData.source === 'component') {
            if (fetched_details.commonComponentData?.datesData.url?.includes('user-engagement-detail')) {
                getUserEngagementDetailUserName(component_path[component_path.length - 1]);
                getUserEngagementDetailSummaryTableData('table');
                getUserEngagementDetailSummaryTableData('list');
            }
        }

    }, [fetched_details?.commonComponentData?.datesData]);

    // API Calls @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const getUserEngagementDetailUserName = (userEmail_base64code: string, apiErrorFlag?: string) => {
        let params = {
            "user_email": userEmail_base64code,
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
            "app_id": fetched_details?.commonComponentData.appsData.appId,
            'env_code': fetched_details?.commonComponentData.envFilter.envCode ?? '',

        };

        userEngagementDetailActions.getUserEngagementDetailData(
            params,
            "USER_ENGAGEMENT_DETAIL_USER_NAME",
            ActionTypes.SET_USER_ENGAGEMENT_DETAIL_USER_NAME,
            apiErrorFlag ?? '',
        ).then((res: any) => dispatch(res));

    };

    const getUserEngagementDetailTimelineData = (apiErrorFlag?: string) => {
        let params = {
            "unique_user_id": userEngagementId,
            "app_id": fetched_details?.commonComponentData.appsData.appId,
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
            "event_source": fetched_details?.eventSource.eventSourceUserEngagement,
            "event_type": timelineData.eventType,
            "filter": fetched_details?.statusFilter?.stateFilter?.toLowerCase(),
            'exclude_weekends': fetched_details?.commonComponentData.datesData.excludeFlag,
            'enable_real_time': fetched_details?.commonComponentData.datesData.realTimeCheck,
            "page_index": timelineData.page,
            'page_size': timelineData.limit,
            'guide_id': guideDetail.guideID,
            'env_code': fetched_details?.commonComponentData.envFilter.envCode ?? '',

        };

        if (guideDetail.guideID) {
            userEngagementDetailActions.getUserEngagementDetailData(
                params,
                "USER_ENGAGEMENT_DETAIL_TIMELINE_DATA",
                ActionTypes.SET_USER_ENGAGEMENT_DETAIL_TIMELINE_DATA,
                apiErrorFlag ?? '',
                timelineData.firstLoad
            ).then((res: any) => dispatch(res));
        }
    };

    const getUserEngagementGuideMeChartData = (apiErrorFlag?: string) => {
        let params = {
            "unique_user_id": userEngagementId,
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
            "event_source": fetched_details?.eventSource.eventSourceUserEngagement,
            "filter": fetched_details?.statusFilter?.stateFilter?.toLowerCase(),
            'exclude_weekends': fetched_details?.commonComponentData.datesData.excludeFlag,
            'enable_real_time': fetched_details?.commonComponentData.datesData.realTimeCheck,
            'guide_id': guideDetail.guideID,
            "app_id": fetched_details?.commonComponentData.appsData.appId,
            'env_code': fetched_details?.commonComponentData.envFilter.envCode ?? '',

        };

        if (guideDetail.guideID) {
            userEngagementDetailActions.getUserEngagementDetailData(
                params,
                "USER_ENGAGEMENT_DETAIL_GUIDEME_CHART",
                ActionTypes.SET_USER_ENGAGEMENT_DETAIL_GUIDEME_CHART,
                apiErrorFlag ?? '',
            ).then((res: any) => dispatch(res));
        }
    };

    const getUserEngagementShowMeChartData = (apiErrorFlag?: string) => {
        let params = {
            "unique_user_id": userEngagementId,
            "app_id": fetched_details?.commonComponentData.appsData.appId,
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
            "event_source": fetched_details?.eventSource.eventSourceUserEngagement,
            "filter": fetched_details?.statusFilter?.stateFilter?.toLowerCase(),
            'exclude_weekends': fetched_details?.commonComponentData.datesData.excludeFlag,
            'enable_real_time': fetched_details?.commonComponentData.datesData.realTimeCheck,
            'guide_id': guideDetail.guideID,
            'env_code': fetched_details?.commonComponentData.envFilter.envCode ?? '',

        };

        if (guideDetail.guideID) {
            userEngagementDetailActions.getUserEngagementDetailData(
                params,
                "USER_ENGAGEMENT_DETAIL_SHOWME_CHART",
                ActionTypes.SET_USER_ENGAGEMENT_DETAIL_SHOWME_CHART,
                apiErrorFlag ?? '',
            ).then((res: any) => dispatch(res));
        }

    };

    const getUserEngagementTestMeChartData = (apiErrorFlag?: string) => {
        let params = {
            "unique_user_id": userEngagementId,
            "app_id": fetched_details?.commonComponentData.appsData.appId,
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
            "event_source": fetched_details?.eventSource.eventSourceUserEngagement,
            "filter": fetched_details?.statusFilter?.stateFilter?.toLowerCase(),
            'exclude_weekends': fetched_details?.commonComponentData.datesData.excludeFlag,
            'enable_real_time': fetched_details?.commonComponentData.datesData.realTimeCheck,
            'guide_id': guideDetail.guideID,
            'env_code': fetched_details?.commonComponentData.envFilter.envCode ?? '',

        };

        if (guideDetail.guideID) {
            userEngagementDetailActions.getUserEngagementDetailData(
                params,
                "USER_ENGAGEMENT_DETAIL_TESTME_CHART",
                ActionTypes.SET_USER_ENGAGEMENT_DETAIL_TESTME_CHART,
                apiErrorFlag ?? '',
            ).then((res: any) => dispatch(res));
        }

    };

    const getUserEngagementDoItForMeChartData = (apiErrorFlag?: string) => {
        let params = {
            "unique_user_id": userEngagementId,
            "app_id": fetched_details?.commonComponentData.appsData.appId,
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
            "event_source": fetched_details?.eventSource.eventSourceUserEngagement,
            "filter": fetched_details?.statusFilter?.stateFilter?.toLowerCase(),
            'exclude_weekends': fetched_details?.commonComponentData.datesData.excludeFlag,
            'enable_real_time': fetched_details?.commonComponentData.datesData.realTimeCheck,
            'guide_id': guideDetail.guideID,
            'env_code': fetched_details?.commonComponentData.envFilter.envCode ?? '',

        };

        if (guideDetail.guideID) {
            userEngagementDetailActions.getUserEngagementDetailData(
                params,
                "USER_ENGAGEMENT_DETAIL_DO_IT_FOR_ME_CHART",
                ActionTypes.SET_USER_ENGAGEMENT_DETAIL_DO_IT_FOR_ME_CHART,
                apiErrorFlag ?? '',
            ).then((res: any) => dispatch(res));
        }
    };

    const getUserEngagementTutorialGuidesChartData = (apiErrorFlag?: string) => {
        let params = {
            "unique_user_id": userEngagementId,
            "app_id": fetched_details?.commonComponentData.appsData.appId,
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
            "event_source": fetched_details?.eventSource.eventSourceUserEngagement,
            "filter": fetched_details?.statusFilter?.stateFilter?.toLowerCase(),
            'exclude_weekends': fetched_details?.commonComponentData.datesData.excludeFlag,
            'enable_real_time': fetched_details?.commonComponentData.datesData.realTimeCheck,
            'guide_id': guideDetail.guideID,
            'env_code': fetched_details?.commonComponentData.envFilter.envCode ?? '',

        };

        if (guideDetail.guideID) {
            userEngagementDetailActions.getUserEngagementDetailData(
                params,
                "USER_ENGAGEMENT_DETAIL_TUTORIAL_GUIDES_CHART",
                ActionTypes.SET_USER_ENGAGEMENT_DETAIL_TUTORIAL_GUIDES_CHART_DATA,
                apiErrorFlag ?? '',
            ).then((res: any) => dispatch(res));
        }
    };

    const getUserEngagementDetailSummaryTableData = (optn: string, apiErrorFlag?: string) => {
        try {
            let limit;
            let sortKey;
            let sortValue;
            let page;
            let searchQuery;
            let actionType;

            if (optn === 'list') {
                limit = 9999;
                sortKey = 'guide_name';
                sortValue = 'asc';
                page = 1;
                searchQuery = '';
                actionType = ActionTypes.SET_USER_ENGAGEMENT_DETAIL_LIST_DATA;
            } else {
                limit = userEngagementDetailSummaryTable.limit;
                sortKey = userEngagementDetailSummaryTable.sortKey;
                sortValue = userEngagementDetailSummaryTable.sortValue;
                page = userEngagementDetailSummaryTable.page;
                searchQuery = userEngagementDetailSummaryTable.searchQuery;
                actionType = ActionTypes.SET_USER_ENGAGEMENT_DETAIL_SUMMARY_TABLE_DATA;
            }

            let params = {
                "unique_user_id": userEngagementId,
                "time_zone": timeZone,
                "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
                "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
                "app_id": fetched_details?.commonComponentData.appsData.appId,
                "event_source": fetched_details?.eventSource.eventSourceUserEngagement,
                "filter": fetched_details?.statusFilter?.stateFilter?.toLowerCase(),
                "page_size": limit,
                "sort_by": sortKey,
                "order": sortValue,
                "page_index": page,
                "query": encodeURIComponent(searchQuery),
                'exclude_weekends': fetched_details?.commonComponentData.datesData.excludeFlag,
                'enable_real_time': fetched_details?.commonComponentData.datesData.realTimeCheck,
                'env_code': fetched_details?.commonComponentData.envFilter.envCode ?? '',

            };

            userEngagementDetailActions.getUserEngagementDetailData(
                params,
                "USER_ENGAGEMENT_DETAIL_SUMMARY_TABLE",
                actionType,
                apiErrorFlag ?? '',
                userEngagementDetailSummaryTable.firstLoad
            ).then((res: any) => dispatch(res));
        } catch (error) {
            showBoundary(error)
        }

    };

    const exportExcel = (data: any) => {
        let params = {
            "unique_user_id": userEngagementId,
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
            "app_id": fetched_details?.commonComponentData.appsData.appId,
            "event_source": fetched_details?.eventSource.eventSourceUserEngagement,
            "page_size": userEngagementDetailSummaryTable.limit,
            "sort_by": userEngagementDetailSummaryTable.sortKey,
            "order": userEngagementDetailSummaryTable.sortValue,
            "page_index": userEngagementDetailSummaryTable.page,
            "query": encodeURIComponent(userEngagementDetailSummaryTable.searchQuery),
            'exclude_weekends': fetched_details?.commonComponentData.datesData.excludeFlag,
            'enable_real_time': fetched_details?.commonComponentData.datesData.realTimeCheck
        };
        getCallExportExcel(params, 'USER_ENGAGEMENT_DETAIL_SUMMARY_TABLE', data);
    }

    const getGuideStatus = (guide: any, apiErrorFlag?: string) => {
        try {

            if(fetched_details?.commonComponentData?.appsData.appCode == localStorage.getItem('switch_app_code')) {
                let params = {
                    "guide_id": guide['value'],
                    "app_id": fetched_details?.commonComponentData.appsData.appId
                };
    
                const id = guide['value'];
                const isTutorialGuide = guide['isTutorialGuide'];
    
                getCall(params, "GUIDE_STATUS", apiErrorFlag ?? '').then((data: any) => {
                    if (data.result === "success") {
                        if (data.data.is_deleted) {
                            setGuideDetail((prevState: any) => {
                                return {
                                    ...prevState,
                                    guideStatus: insightsLbls['deleted'],
                                    guideColor: 'colorRed',
                                    guideStatus_flag: true,
                                    guideName: guide['label'],
                                    isTutorialGuide: isTutorialGuide,
                                    guideID: id,
                                }
                            })
                        } else {
                            if (data.data.is_published) {
                                setGuideDetail((prevState: any) => {
                                    return {
                                        ...prevState,
                                        guideStatus: insightsLbls['published'],
                                        guideColor: 'colorGreen',
                                        guideStatus_flag: true,
                                        guideName: guide['label'],
                                        isTutorialGuide: isTutorialGuide,
                                        guideID: id,
                                    }
                                })
                            } else {
                                setGuideDetail((prevState: any) => {
                                    return {
                                        ...prevState,
                                        guideStatus: insightsLbls['unpublished'],
                                        guideColor: 'colorGrey',
                                        guideStatus_flag: true,
                                        guideName: guide['label'],
                                        isTutorialGuide: isTutorialGuide,
                                        guideID: id,
                                    }
                                })
                            }
                        }
                    } else if (data.result === "retry") {
                        setTimeout(() => {
                            getGuideStatus(id, 'retry')
                        }, RetryApi.TIMEOUT)
                    } else {
                        setGuideDetail((prevState: any) => {
                            return {
                                ...prevState,
                                guideStatus: insightsLbls['unknown'],
                                guideColor: 'colorYellow',
                                guideStatus_flag: true,
                                isTutorialGuide: isTutorialGuide,
                                guideID: id,
                            }
                        })
                    }
                });
            }
            
        } catch (error) {
            showBoundary(error)
        }

    }

    //Functions @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const apiCatchError = (index: number, option: number) => {
        try {
            setErrorCount((prevState: any) => {
                return {
                    ...prevState,
                    errorCount: errorCount.errorCount.map((item: any, i: any) =>
                        i === index ? option : item
                    ),
                };
            });
        } catch (error) {
            showBoundary(error)
        }

    };

    const enableExportPdf = (loaderNumber: number, val: boolean) => {
        try {
            setLoaderBtn((prevState: any) => {
                return {
                    ...prevState,
                    ['loader' + loaderNumber]: val
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const refreshSections = (optn: number) => {
        try {
            enableExportPdf(optn, false)
            if (optn === 0) {
                setUserEngagementDetailSummaryTable((prevState: any) => {
                    return {
                        ...prevState,
                        userEngagementDetailSummaryTableDataFetched: false,

                        searchQuery: '',
                        sortKey: 'guide_name',
                        filterLabels: [],
                        page: 1,
                        showExport: true,
                        defaultSort: {
                            'sortColumn_index': 2,
                            'sortValue': 'desc'
                        },
                        // renderTaggedTable: false,
                        sortValue: 'desc',
                        limit: 5,
                        tablePagination: {
                            offset: 0,
                            data: [],
                            perPage: 5,
                            currentPage: 0
                        },
                    };
                });
            }
            else if (optn === 1) {
                setGuideDetail((prevState: any) => {
                    return {
                        ...prevState,
                        guideStatus: '',
                        guideColor: '',
                        guideStatus_flag: false,
                        guideName: '',
                        isTutorialGuide: null,
                        guideID: null,
                    };
                });

                setListData((prevState: any) => {
                    return {
                        ...prevState,
                        guidesData: [],
                        guideDataFetched: false,
                    };
                });
                getUserEngagementDetailSummaryTableData('list');
            }
        } catch (error) {
            showBoundary(error)
        }

    };

    // UseEffects @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    //Filter Change
    useEffect(() => {
        try {
            if (SummaryMount.current === true && ListMount.current === true) {
                enableExportPdf(0, false)
                enableExportPdf(1, false)

                setUserEngagementDetailSummaryTable((prevState: any) => {
                    return {
                        ...prevState,
                        userEngagementDetailSummaryTableDataFetched: false,
                        searchQuery: '',
                        sortKey: 'guide_name',
                        filterLabels: [],
                        page: 1,
                        showExport: true,
                        defaultSort: {
                            'sortColumn_index': 0,
                            'sortValue': 'asc'
                        },
                        sortValue: 'asc',
                        limit: 5,
                        tablePagination: {
                            offset: 0,
                            data: [],
                            perPage: 5,
                            currentPage: 0
                        },
                        firstLoad: true
                    };
                });

                setListData((prevState: any) => {
                    return {
                        ...prevState,
                        guideDataFetched: false,
                    };
                });
                getUserEngagementDetailSummaryTableData('list');
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.statusFilter]);

    //Summary Table
    useEffect(() => {
        try {
            if (
                fetched_details.userEngagementDetails.userEngagementDetailSummaryTableData.data !== undefined &&
                fetched_details.userEngagementDetails.userEngagementDetailSummaryTableData.result === "success"
            ) {
                SummaryMount.current = true;
                setUserEngagementDetailSummaryTable((prevState: any) => {
                    return {
                        ...prevState,
                        userEngagementDetailSummaryTableDataFetched: true,
                        errorMsg: ''
                    };
                });
                apiCatchError(0, 0);
                enableExportPdf(0, true)
            } else if (fetched_details.userEngagementDetails.userEngagementDetailSummaryTableData.result === 'retry') {
                getUserEngagementDetailSummaryTableData('table', 'retry');
            } else if (
                fetched_details.userEngagementDetails.userEngagementDetailSummaryTableData.result === "error"
            ) {

                let errorMsg = insightsLbls.serviceNotAvailable;
                let errorFlag = 1;
                if (fetched_details.userEngagementDetails.userEngagementDetailSummaryTableData.errorCode === 409) {
                    errorMsg = insightsLbls.conflictingParameters;
                    errorFlag = 2;
                }

                setUserEngagementDetailSummaryTable((prevState: any) => {
                    return {
                        ...prevState,
                        userEngagementDetailSummaryTableDataFetched: true,
                        errorMsg: errorMsg
                    };
                });
                apiCatchError(0, errorFlag);
                enableExportPdf(0, false)
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.userEngagementDetails.userEngagementDetailSummaryTableData]);

    //Guide List
    useEffect(() => {
        try {
            if (
                fetched_details.userEngagementDetails.userEngagementDetailListData.data !== undefined &&
                fetched_details.userEngagementDetails.userEngagementDetailListData.result === "success"
            ) {
                ListMount.current = true;
                let dummyData: any = [];
                for (let data of fetched_details.userEngagementDetails.userEngagementDetailListData.data.tableData) {
                    dummyData.push({ guideName: data['guideName'], guideID: data['guideID'], tutorialCount: data['tutorialPlayCount'] })
                }
                setListData((prevState: any) => {
                    return {
                        ...prevState,
                        guidesData: dummyData,
                        guideDataFetched: true,
                        errorMsg: ''
                    }
                })
                apiCatchError(0, 0);
                enableExportPdf(0, true)
            } else if (fetched_details.userEngagementDetails.userEngagementDetailListData.result === 'retry') {
                getUserEngagementDetailSummaryTableData('list', 'retry');
            } else if (
                fetched_details.userEngagementDetails.userEngagementDetailListData.result === "error"
            ) {
                let errorMsg = insightsLbls.serviceNotAvailable;
                let errorFlag = 1;
                if (fetched_details.userEngagementDetails.userEngagementDetailListData.errorCode === 409) {
                    errorMsg = insightsLbls.conflictingParameters;
                    errorFlag = 2;
                }

                setListData((prevState: any) => {
                    return {
                        ...prevState,
                        guideDataFetched: true,
                        errorMsg: errorMsg
                    }
                })
                apiCatchError(0, errorFlag);
                enableExportPdf(0, false)
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.userEngagementDetails.userEngagementDetailListData]);

    //User Name
    useEffect(() => {
        try {
            if (
                fetched_details?.userEngagementDetails?.userEngagementDetailUserName?.data !== undefined &&
                fetched_details?.userEngagementDetails?.userEngagementDetailUserName?.result === "success"
            ) {
                setUserName(fetched_details?.userEngagementDetails?.userEngagementDetailUserName?.data?.display_name);
                apiCatchError(7, 0);
                enableExportPdf(0, true)
            } else if (fetched_details?.userEngagementDetails?.userEngagementDetailUserName?.result === 'retry') {
                getUserEngagementDetailSummaryTableData('list', 'retry');
            } else if (
                fetched_details?.userEngagementDetails?.userEngagementDetailUserName?.result === "error"
            ) {
                // setListData((prevState: any) => {
                //     return {
                //         ...prevState,
                //         guideDataFetched: true
                //     }
                // })
                apiCatchError(7, 1);
                enableExportPdf(0, false)
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details?.userEngagementDetails?.userEngagementDetailUserName]);

    //Change of Guides
    useEffect(() => {
        try {
            if (guideDetail.guideID !== null && guideDetail.isTutorialGuide !== null) {
                if (guideDetail.isTutorialGuide) {
                    setUserEngagementTutorialGuidesChart((prevState: any) => {
                        return {
                            ...prevState,
                            userEngagementTutorialGuidesChartDataFetched: false,
                        };
                    });
                    getUserEngagementTutorialGuidesChartData();
                }
                else {
                    setUserEngagementGuideMeChart((prevState: any) => {
                        return {
                            ...prevState,
                            userEngagementGuideMeChartDataFetched: false,
                        };
                    });
                    setUserEngagementShowMeChart((prevState: any) => {
                        return {
                            ...prevState,
                            userEngagementShowMeChartDataFetched: false,
                        };
                    });
                    setUserEngagementTestMeChart((prevState: any) => {
                        return {
                            ...prevState,
                            userEngagementTestMeChartDataFetched: false,
                        };
                    });
                    setUserEngagementDoItForMeChart((prevState: any) => {
                        return {
                            ...prevState,
                            userEngagementDoItForMeChartDataFetched: false,
                        };
                    });
                    setTimelineData((prevState: any) => {
                        return {
                            ...prevState,
                            dataFetched: false,
                            page: 1,
                            limit: 5,
                            cardPagination: {
                                offset: 0,
                                data: [],
                                perPage: 5,
                                currentPage: 0
                            },
                            firstLoad: true,
                            eventType: 'all'
                        }
                    })
                    getUserEngagementGuideMeChartData();
                    getUserEngagementShowMeChartData();
                    getUserEngagementTestMeChartData();
                    getUserEngagementDoItForMeChartData();
                    getUserEngagementDetailTimelineData();
                }
            }

        } catch (error) {
            showBoundary(error)
        }

    }, [guideDetail.guideID, guideDetail.isTutorialGuide]);

    //GuideMeChart
    useEffect(() => {
        try {
            if (
                fetched_details.userEngagementDetails.userEngagementDetailGuideMeChart.data !== undefined &&
                fetched_details.userEngagementDetails.userEngagementDetailGuideMeChart.result === "success"
            ) {
                GuideMeMount.current = true;
                let guideMeChartData: any = [];
                guideMeChartData = fetched_details.userEngagementDetails.userEngagementDetailGuideMeChart.data;

                setUserEngagementGuideMeChart((prevState: any) => {
                    return {
                        ...prevState,
                        loadGuideMeChartData: [...guideMeChartData],
                        userEngagementGuideMeChartDataFetched: true,
                        errorMsg: ''
                    };
                });
                apiCatchError(1, 0);
                enableExportPdf(1, true)
            } else if (fetched_details.userEngagementDetails.userEngagementDetailGuideMeChart.result === 'retry') {
                getUserEngagementGuideMeChartData('retry');
            } else if (
                fetched_details.userEngagementDetails.userEngagementDetailGuideMeChart.result === "error"
            ) {

                let errorMsg = insightsLbls.serviceNotAvailable;
                let errorFlag = 1;
                if (fetched_details.userEngagementDetails.userEngagementDetailGuideMeChart.errorCode === 409) {
                    errorMsg = insightsLbls.conflictingParameters;
                    errorFlag = 2;
                }

                setUserEngagementGuideMeChart((prevState: any) => {
                    return {
                        ...prevState,
                        userEngagementGuideMeChartDataFetched: true,
                        errorMsg: errorMsg
                    };
                });
                apiCatchError(1, errorFlag);
                enableExportPdf(1, false)
            }
        } catch (error) {
            showBoundary(error)
        }


    }, [fetched_details.userEngagementDetails.userEngagementDetailGuideMeChart]);

    //ShowMeChart  
    useEffect(() => {
        try {
            if (
                fetched_details.userEngagementDetails.userEngagementDetailShowMeChart.data !== undefined &&
                fetched_details.userEngagementDetails.userEngagementDetailShowMeChart.result === "success"
            ) {
                ShowMeMount.current = true;
                let showMeChartData: any = [];

                showMeChartData = fetched_details.userEngagementDetails.userEngagementDetailShowMeChart.data;
                renderDonutChart(showMeChartData);
                renderPieChart(showMeChartData);
                setUserEngagementShowMeChart((prevState: any) => {
                    return {
                        ...prevState,
                        loadShowMeChartData: [...showMeChartData],
                        userEngagementShowMeChartDataFetched: true,
                        errorMsg: ''
                    };
                });

                apiCatchError(2, 0);
                enableExportPdf(1, true)

            } else if (fetched_details.userEngagementDetails.userEngagementDetailShowMeChart.result === 'retry') {
                getUserEngagementShowMeChartData('retry');
            } else if (
                fetched_details.userEngagementDetails.userEngagementDetailShowMeChart.result === "error"
            ) {

                let errorMsg = insightsLbls.serviceNotAvailable;
                let errorFlag = 1;
                if (fetched_details.userEngagementDetails.userEngagementDetailShowMeChart.errorCode === 409) {
                    errorMsg = insightsLbls.conflictingParameters;
                    errorFlag = 2;
                }

                setUserEngagementShowMeChart((prevState: any) => {
                    return {
                        ...prevState,
                        userEngagementShowMeChartDataFetched: true,
                        errorMsg: errorMsg
                    };
                });
                apiCatchError(2, errorFlag);
                enableExportPdf(1, false)
            }

        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.userEngagementDetails.userEngagementDetailShowMeChart]);

    //TestMeChart
    useEffect(() => {
        try {
            if (
                fetched_details.userEngagementDetails.userEngagementDetailTestMeChart.data !== undefined &&
                fetched_details.userEngagementDetails.userEngagementDetailTestMeChart.result === "success"
            ) {
                TestMeMount.current = true;
                let testMeChartData: any = [];
                testMeChartData = fetched_details.userEngagementDetails.userEngagementDetailTestMeChart.data;

                setUserEngagementTestMeChart((prevState: any) => {
                    return {
                        ...prevState,
                        loadTestMeChartData: [...testMeChartData],
                        userEngagementTestMeChartDataFetched: true,
                        errorMsg: ''
                    };
                });
                apiCatchError(3, 0);
                enableExportPdf(1, true)

            } else if (fetched_details.userEngagementDetails.userEngagementDetailTestMeChart.result === 'retry') {
                getUserEngagementTestMeChartData('retry');
            } else if (
                fetched_details.userEngagementDetails.userEngagementDetailTestMeChart.result === "error"
            ) {

                let errorMsg = insightsLbls.serviceNotAvailable;
                let errorFlag = 1;
                if (fetched_details.userEngagementDetails.userEngagementDetailTestMeChart.errorCode === 409) {
                    errorMsg = insightsLbls.conflictingParameters;
                    errorFlag = 2;
                }

                setUserEngagementTestMeChart((prevState: any) => {
                    return {
                        ...prevState,
                        userEngagementTestMeChartDataFetched: true,
                        errorMsg: errorMsg
                    };
                });
                apiCatchError(3, errorFlag);
                enableExportPdf(1, false)
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.userEngagementDetails.userEngagementDetailTestMeChart]);

    //DoItForMeChart
    useEffect(() => {
        try {
            if (
                fetched_details.userEngagementDetails.userEngagementDetailDoItForMeChart.data !== undefined &&
                fetched_details.userEngagementDetails.userEngagementDetailDoItForMeChart.result === "success"
            ) {
                DoItForMeMount.current = true;
                let doItForMeChartData: any = [];
                doItForMeChartData = fetched_details.userEngagementDetails.userEngagementDetailDoItForMeChart.data;

                setUserEngagementDoItForMeChart((prevState: any) => {
                    return {
                        ...prevState,
                        loadDoItForMeChartData: [...doItForMeChartData],
                        userEngagementDoItForMeChartDataFetched: true,
                        errorMsg: ''
                    };
                });
                apiCatchError(4, 0);
                enableExportPdf(1, true)
            } else if (fetched_details.userEngagementDetails.userEngagementDetailDoItForMeChart.result === 'retry') {
                getUserEngagementDoItForMeChartData('retry');
            } else if (
                fetched_details.userEngagementDetails.userEngagementDetailDoItForMeChart.result === "error"
            ) {

                let errorMsg = insightsLbls.serviceNotAvailable;
                let errorFlag = 1;
                if (fetched_details.userEngagementDetails.userEngagementDetailDoItForMeChart.errorCode === 409) {
                    errorMsg = insightsLbls.conflictingParameters;
                    errorFlag = 2;
                }

                setUserEngagementDoItForMeChart((prevState: any) => {
                    return {
                        ...prevState,
                        userEngagementDoItForMeChartDataFetched: true,
                        errorMsg: errorMsg
                    };
                });
                apiCatchError(4, errorFlag);
                enableExportPdf(1, false)
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.userEngagementDetails.userEngagementDetailDoItForMeChart]);

    //TimelineList
    useEffect(() => {
        try {
            if (fetched_details.userEngagementDetails.userEngagementDetailTimelineData.data !== undefined &&
                fetched_details.userEngagementDetails.userEngagementDetailTimelineData.result === "success") {
                TimeLineMount.current = true;


                let dummyData = {
                    guideDetails: [],
                    totalCount: 0
                }

                for (let itr0 = 0; itr0 < fetched_details.userEngagementDetails.userEngagementDetailTimelineData.data.totalCount; itr0++) {
                    if (fetched_details.userEngagementDetails.userEngagementDetailTimelineData?.data?.guideDetails[itr0]?.start_timestamp && !(fetched_details.userEngagementDetails.userEngagementDetailTimelineData?.data?.guideDetails[itr0]?.start_timestamp.includes('NaN'))) {
                        dummyData.guideDetails.push(fetched_details.userEngagementDetails.userEngagementDetailTimelineData.data.guideDetails[itr0])
                    }
                }

                dummyData.totalCount = dummyData.guideDetails.length;

                setTimelineData((prevState: any) => {
                    return {
                        ...prevState,
                        dataList: dummyData,
                        dataCount: dummyData.guideDetails.length,
                        dataFetched: true,
                        errorMsg: ''
                    }
                })


                // setTimelineData((prevState: any) => {
                //     return {
                //         ...prevState,
                //         dataList: fetched_details.userEngagementDetails.userEngagementDetailTimelineData.data,
                //         dataCount: fetched_details.userEngagementDetails.userEngagementDetailTimelineData.data.totalCount,
                //         dataFetched: true,
                //         errorMsg: ''
                //     }
                // })
                apiCatchError(5, 0);
                enableExportPdf(1, true);
            } else if (fetched_details.userEngagementDetails.userEngagementDetailTimelineData.result === 'retry') {
                getUserEngagementDetailTimelineData('retry');
            } else if (fetched_details.userEngagementDetails.userEngagementDetailTimelineData.result === "error") {

                let errorMsg = insightsLbls.serviceNotAvailable;
                let errorFlag = 1;
                if (fetched_details.userEngagementDetails.userEngagementDetailTimelineData.errorCode === 409) {
                    errorMsg = insightsLbls.conflictingParameters;
                    errorFlag = 2;
                }

                setTimelineData((prevState: any) => {
                    return {
                        ...prevState,
                        dataFetched: true,
                        errorMsg: errorMsg
                    }
                })
                apiCatchError(5, errorFlag);
                enableExportPdf(1, false)
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.userEngagementDetails.userEngagementDetailTimelineData]);

    //TutorialGuidesChart
    useEffect(() => {
        try {
            if (
                fetched_details.userEngagementDetails.userEngagementDetailTutorialGuidesChart.data !== undefined &&
                fetched_details.userEngagementDetails.userEngagementDetailTutorialGuidesChart.result === "success"
            ) {
                TutorialGuidesMount.current = true;
                let tutorialGuidesChartData: any = [];
                tutorialGuidesChartData = fetched_details.userEngagementDetails.userEngagementDetailTutorialGuidesChart.data;

                setUserEngagementTutorialGuidesChart((prevState: any) => {
                    return {
                        ...prevState,
                        userEngagementTutorialGuidesChartDataFetched: false,
                    };
                });

                setUserEngagementTutorialGuidesChart((prevState: any) => {
                    return {
                        ...prevState,
                        loadTutorialGuidesChartData: [...tutorialGuidesChartData],
                        userEngagementTutorialGuidesChartDataFetched: true,
                        errorMsg: ''
                    };
                });
                apiCatchError(6, 0);
                enableExportPdf(1, true)
            } else if (fetched_details.userEngagementDetails.userEngagementDetailTutorialGuidesChart.result === 'retry') {
                getUserEngagementTutorialGuidesChartData('retry');
            } else if (
                fetched_details.userEngagementDetails.userEngagementDetailTutorialGuidesChart.result === "error"
            ) {

                let errorMsg = insightsLbls.serviceNotAvailable;
                let errorFlag = 1;
                if (fetched_details.userEngagementDetails.userEngagementDetailTutorialGuidesChart.errorCode === 409) {
                    errorMsg = insightsLbls.conflictingParameters;
                    errorFlag = 2;
                }

                setUserEngagementTutorialGuidesChart((prevState: any) => {
                    return {
                        ...prevState,
                        userEngagementTutorialGuidesChartDataFetched: true,
                        errorMsg: errorMsg
                    };
                });
                apiCatchError(6, errorFlag);
                enableExportPdf(1, false)
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.userEngagementDetails.userEngagementDetailTutorialGuidesChart]);

    //Export PDF Btn
    useEffect(() => {
        try {
            if (loaderBtn.loader0 && loaderBtn.loader1) {
                setLoaderBtn((prevState: any) => {
                    return {
                        ...prevState,
                        disabled: false,
                    };
                });
            }
            else {
                setLoaderBtn((prevState: any) => {
                    return {
                        ...prevState,
                        disabled: true,
                    };
                });
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [loaderBtn.loader0, loaderBtn.loader1])

    //Call summary table api on table related actions changes
    useEffect(() => {
        try {
            if (fetched_details?.userEngagementDetails?.userEngagementDetailSummaryTableData?.data) {
                enableExportPdf(0, false)
                getUserEngagementDetailSummaryTableData('table');
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [
        userEngagementDetailSummaryTable.sortKey,
        userEngagementDetailSummaryTable.sortValue,
        userEngagementDetailSummaryTable.defaultSort,
        userEngagementDetailSummaryTable.page,
        userEngagementDetailSummaryTable.searchQuery,
        userEngagementDetailSummaryTable.limit,
    ]);

    //Call guidelist api on table related actions changes
    useEffect(() => {
        try {
            if (fetched_details.userEngagementDetails.userEngagementDetailTimelineData.data) {
                enableExportPdf(1, false)
                getUserEngagementDetailTimelineData();
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [
        timelineData.page,
        timelineData.eventType,
        timelineData.limit
    ]);


    useEffect(() => {
        try {
            const result = errorCount.errorCount.every((val) => val === 1);
            setErrorCount((prevState: any) => {
                return {
                    ...prevState,
                    showMaintenance_flag: result
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }, [errorCount.errorCount])

    // Chart Renders and Callbacks @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const renderDonutChart = (data: any) => {
        try {
            let total = 0
            for (let i = 0; i < data.length; i++) {
                total += data[i].counts.guide_video_play + data[i].counts.guide_gif_play + data[i].counts.guide_slideshow_play;
                if (total) break;
            }
            let donutDataAvailable: boolean = false;
            total === 0 ? (donutDataAvailable = false) : (donutDataAvailable = true)
            setUserEngagementShowMeChart((prevState: any) => {
                return {
                    ...prevState,
                    showMeDonutChartData: [...data],
                    showMeDonutDataAvailable: donutDataAvailable,
                };
            });
        } catch (error) {
            showBoundary(error)
        }

    }

    const renderPieChart = (data: any) => {
        try {
            let total = 0;
            for (let itr1 = 0; itr1 < data.length; itr1++) {
                total += data[itr1].counts.guide_video_download + data[itr1].counts.guide_gif_download + data[itr1].counts.guide_slideshow_download + data[itr1].counts.guide_pdf_download + data[itr1].counts.guide_docx_download + data[itr1].counts.guide_html_download;
                if (total) break;
            }
            let pieDataAvailable: boolean = false;
            total === 0 ? (pieDataAvailable = false) : (pieDataAvailable = true)
            setUserEngagementShowMeChart((prevState: any) => {
                return {
                    ...prevState,
                    showMePieChartData: [...data],
                    showMePieDataAvailable: pieDataAvailable,
                };
            });
        } catch (error) {
            showBoundary(error)
        }

    }

    const getPieData = useCallback(
        () => {
            try {
                return userEngagementShowMeChart.showMePieChartData

            } catch (error) {
                showBoundary(error)
            }
        },
        [userEngagementShowMeChart.showMePieChartData]
    );

    const getDonutData = useCallback(
        () => {
            try {
                return userEngagementShowMeChart.showMeDonutChartData

            } catch (error) {
                showBoundary(error)
            }
        },
        [userEngagementShowMeChart.showMeDonutChartData]
    );

    const handleClick = useCallback((option: string, data: any) => {
        try {
            switch (option) {
                case "changePage":
                    setTimelineData((prevState) => {
                        return {
                            ...prevState,
                            page: data + 1,
                            firstLoad: false
                        }
                    });
                    break;

                case "changeEntries":
                    setTimelineData((prevState) => {
                        return {
                            ...prevState,
                            page: 1,
                            limit: data,
                            cardPagination: {                   // object that we want to update
                                ...prevState.cardPagination,    // keep all other key-value pairs
                                perPage: data       // update the value of specific key
                            },
                            firstLoad: false,
                        };
                    });
                    break;

                case "changeEvent":
                    setTimelineData((prevState) => {
                        return {
                            ...prevState,
                            page: 1,
                            firstLoad: false,
                            eventType: data
                        };
                    });
                    break;
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [timelineData]);

    const handleTableEvents = useCallback((option: string, data: any) => {
        try {
            switch (option) {
                case "sortColumn":

                    setUserEngagementDetailSummaryTable((prevState) => {
                        return {
                            ...prevState,
                            sortKey: data.sortKey,
                            sortValue: data.sortValue,
                            page: 1,
                            defaultSort: {
                                sortColumn_index: data.index,
                                sortValue: data.sortValue,
                            },
                            firstLoad: false
                        }
                    });

                    break;

                case "changePage":

                    setUserEngagementDetailSummaryTable((prevState) => {
                        return {
                            ...prevState,
                            page: data + 1,
                            firstLoad: false
                        }
                    });
                    break;

                case "searchTxt":

                    setUserEngagementDetailSummaryTable((prevState) => {
                        return {
                            ...prevState,
                            page: 1,
                            searchQuery: data,
                            firstLoad: false
                        }
                    });

                    break;

                case "changeEntries":

                    setUserEngagementDetailSummaryTable((prevState) => {
                        return {
                            ...prevState,
                            page: 1,
                            limit: data,
                            tablePagination: {                   // object that we want to update
                                ...prevState.tablePagination,    // keep all other key-value pairs
                                perPage: data       // update the value of specific key
                            },
                            firstLoad: false
                        }
                    });
                    break;

                case "exportExcel":
                    exportExcel(data)
                    break;
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [userEngagementDetailSummaryTable]);

    const navigateToMainPage = () => {
        let queryParams = {
            app_id: fetched_details?.commonComponentData.appsData.appId,
            app_code: fetched_details?.commonComponentData.appsData.appCode,
            fdate: fetched_details?.commonComponentData.datesData.fdate,
            tdate: fetched_details?.commonComponentData.datesData.tdate,
            event_source: fetched_details?.commonComponentData?.eventSource.eventSourceUserEngagement,
            env: fetched_details?.commonComponentData?.envFilter?.envCode,
        }
        navigate({
            pathname: `/guide-insights/user-engagement/`,
            search: qs.stringify(queryParams)
        })
    }

    return (
        <section className="demo width100 userEngagementDetailSection" ref={componentRef}>
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <SectionHeader>
                        <li>
                            <span className="cursorPointer" onClick={() => navigateToMainPage()}>{insightsLbls['userEngagement']}</span>
                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                        </li>
                        <li className="active">{insightsLbls['userEngagementDetail']}</li>
                    </SectionHeader>
                </div>

                <SectionFilters>
                    <EnvironmentFilter component={"searches"} disabled={true} />
                    <StateFilter component={"userEngagementDetail"} />
                    <Datepicker source={'component'} />
                </SectionFilters>
            </div>

            {/* <SectionButtons sectionTitle={userName} svgImage={"userEngagementDetail"} className={'marginTop-50'}>
                <ExportToPdf
                    componentRef={componentRef}
                    source={"User Engagement Details"}
                    widthDividedBy={10}
                    disabled={loaderBtn.disabled}
                ></ExportToPdf>
            </SectionButtons> */}

            <div className="marginTop-60 exportBtn-div">
                <Dropdown as={ButtonGroup}>
                    <Button variant="info">{insightsLbls.export}</Button>
                    <Dropdown.Toggle split variant="success" id="dropdown-custom-2" />
                    <Dropdown.Menu className="super-colors">
                        <Dropdown.Item eventKey="1" >
                            <ExportToPdf
                                componentRef={componentRef}
                                source={"User Engagement Details"}
                                widthDividedBy={10}
                                disabled={loaderBtn.disabled}
                            ></ExportToPdf>
                        </Dropdown.Item>

                    </Dropdown.Menu>
                </Dropdown>
            </div>

            <UserInfoSection
                startDate={fetched_details?.commonComponentData?.datesData.fdate}
                endDate={fetched_details?.commonComponentData?.datesData.tdate}
                messageText={userName}>

            </UserInfoSection>

            <div id="userEngagementDetailSection">
                {
                    // <SectionRefresh sectionTitle={insightsLbls.userEngagementDetailSummary} refreshClass={""}>
                    //     <i className="fa fa-refresh pull-right pointer row displayFlex" aria-hidden="true" title={insightsLbls['refresh']} onClick={() => refreshSections(0)}></i>
                    // </SectionRefresh>
                }
                <div className="dashboardTiles borderBottom8 borderTop col-lg-12 page_section padding0 height535 blockImportant">
                    <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                        div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent"}
                        div3Classes={"col-sm-12 cardPadding minHeight490 height100-percent"}>
                        <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                            {process.env.REACT_APP_WESTPAC === 'false' ? (
                                <SectionTooltip tooltipText={
                                    {
                                        "subTitle": insightsLbls.userEngagementSummary,
                                        "tooltipText": insightsLbls.userEngagementDetailSummaryTable,
                                        "childern": [
                                            {
                                                "key": insightsLbls.guide + ":",
                                                "value": insightsLbls.userEngagementDetailGuideName
                                            },
                                            {
                                                "key": insightsLbls.guidePlayed + ":",
                                                "value": insightsLbls.userEngagementDetailTotalGuidePlay
                                            },
                                            {
                                                "key": insightsLbls.showMePlayed + ":",
                                                "value": insightsLbls.userEngagementDetailShowMePlay
                                            },
                                            {
                                                "key": insightsLbls.testMePlayed + ":",
                                                "value": insightsLbls.userEngagementDetailTestMePlay
                                            }
                                        ]
                                    }
                                } placement="right"></SectionTooltip>
                            ) : ((<SectionTooltip tooltipText={
                                {
                                    "subTitle": insightsLbls.userEngagementSummary,
                                    "tooltipText": insightsLbls.userEngagementDetailSummaryTable,
                                    "childern": [
                                        {
                                            "key": insightsLbls.guide + ":",
                                            "value": insightsLbls.userEngagementDetailGuideName
                                        },
                                        {
                                            "key": insightsLbls.guidePlayed + ":",
                                            "value": insightsLbls.userEngagementDetailTotalGuidePlay
                                        }
                                    ]
                                }
                            } placement="right"></SectionTooltip>))}
                            <button className="pointer refreshBtn" onClick={() => refreshSections(0)}> {insightsLbls.refresh}</button>
                        </ContainerTooltip>
                        {
                            userEngagementDetailSummaryTable.userEngagementDetailSummaryTableDataFetched ? (
                                errorCount.errorCount[0] === 0 ? (
                                    <div className="commonSummaryTable">
                                        <CommonTable tableId="UserEngagementDetail"
                                            data={fetched_details.userEngagementDetails.userEngagementDetailSummaryTableData.data}
                                            currentPage={userEngagementDetailSummaryTable.page}
                                            barColors=""
                                            linkPresent={false}
                                            showExport={true}
                                            showSearch={true}
                                            showEntries={true}
                                            defaultSort={userEngagementDetailSummaryTable.defaultSort}
                                            tablePagination={userEngagementDetailSummaryTable.tablePagination}
                                            tableEvent={handleTableEvents}
                                            firstTimeloadFlag={fetched_details.userEngagementDetails.userEngagementDetailSummaryTableData.firstTimeload}
                                            modalTable={false}

                                        />
                                    </div>
                                )
                                    : errorCount.errorCount[0] === 1 ? (
                                        <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                                            <div className="displayFlex alignCenter margin-0-auto">
                                                <h5 className="errorCss card-heading font-weight-400 text-center">
                                                    {userEngagementDetailSummaryTable.errorMsg}
                                                </h5>
                                            </div>
                                        </div>
                                    )
                                        : null)
                                : (
                                    <div className="loaderHeight width100 displayFlex minHeight350 top-30 positionRelative">
                                        <div className="displayFlex alignCenter margin-0-auto">
                                            <Loader></Loader>
                                        </div>
                                    </div>
                                )
                        }
                    </CardContainer>
                </div>
            </div>

            <div id="userEngagementDetailSection2">
                {
                    // <SectionRefresh sectionTitle={insightsLbls.userGuideGraph} refreshClass={"marginTop-30"}>
                    //     <i className="fa fa-refresh pull-right pointer row displayFlex" aria-hidden="true" title={insightsLbls['refresh']} onClick={() => refreshSections(1)}></i>
                    // </SectionRefresh>
                }
                <div className="dashboardTiles borderBottom8 borderTop col-lg-12 page_section padding0 height535 blockImportant">
                    {listData.guideDataFetched
                        ? (
                            errorCount.errorCount[0] === 0 ?
                                (
                                    listData.guidesData.length === 0
                                        ? (
                                            <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight450">
                                                <div className="displayFlex alignCenter margin-0-auto">
                                                    <div className="noDataText-font textCenter-absolute">
                                                        {insightsLbls.noRecordFound}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                        :
                                        (
                                            <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                                                div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent"}
                                                div3Classes={"col-sm-12 cardPadding minHeight490 height100-percent"}>
                                                <div className="">
                                                    <div className="filterDiv displayBlock">
                                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                                            <button className="pointer refreshBtn pull-right" onClick={() => refreshSections(1)}> {insightsLbls.refresh}</button>

                                                        </div>
                                                        <div className="col-sm-6 col-md-6 col-lg-6">
                                                            <SearchDropdown list={listData.guidesData} dispatchStatus={getGuideStatus} source={"userEngagementDetail"}></SearchDropdown>
                                                            {guideDetail.guideStatus_flag
                                                                ? (<div className="marginTop-10">
                                                                    <span className={`${guideDetail.guideColor}` + " guideStatus"}>
                                                                        {guideDetail.guideStatus}
                                                                    </span>
                                                                </div>)
                                                                : (<div className="displayFlex alignCenter margin-0-auto">
                                                                    <Loader width={50} height={'auto'} margin={'none'}></Loader>
                                                                </div>)
                                                            }
                                                        </div>
                                                    </div>

                                                </div>
                                                <hr></hr>
                                                {
                                                    guideDetail.isTutorialGuide !== null && guideDetail.isTutorialGuide === false ?
                                                        (
                                                            <>
                                                                <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                                                    <SectionTooltip tooltipText={
                                                                        {
                                                                            "subTitle": insightsLbls.guideMeActivity,
                                                                            "tooltipText": insightsLbls.graphOfLiveGuideActivities,
                                                                            "childern": [
                                                                                {
                                                                                    "key": insightsLbls.totalPlayed + ":",
                                                                                    "value": insightsLbls.totalNumberOfGuidesPlayed
                                                                                },
                                                                                {
                                                                                    "key": insightsLbls.completed + ":",
                                                                                    "value": insightsLbls.totalNumberOfCompleteGuidesPlayed
                                                                                }
                                                                            ]
                                                                        }
                                                                    } placement="right"></SectionTooltip>

                                                                </ContainerTooltip>
                                                                <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section borderBottom minHeight450 padding0">
                                                                    {userEngagementGuideMeChart.userEngagementGuideMeChartDataFetched ? (
                                                                        errorCount.errorCount[1] === 0 ? (
                                                                            userEngagementGuideMeChart.loadGuideMeChartData.length === 0 ? (
                                                                                <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight450">
                                                                                    <div className="displayFlex alignCenter margin-0-auto">
                                                                                        <div className="noDataText-font textCenter-absolute">
                                                                                            {insightsLbls.noRecordFound}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ) : (
                                                                                <LineChart
                                                                                    chartEntities={['guide_play', 'guide_complete']}
                                                                                    optionEntities={[
                                                                                        { key: insightsLbls !== undefined ? insightsLbls['totalPlayed'] : '', color: ChartColors.color2, area: true },
                                                                                        { key: insightsLbls !== undefined ? insightsLbls['completed'] : '', color: ChartColors.color3, area: true }
                                                                                    ]}
                                                                                    chartData={fetched_details?.userEngagementDetails?.userEngagementDetailGuideMeChart?.data}
                                                                                    value_type={"count"}
                                                                                    height={linechart_height}
                                                                                    margin={lineChartMargin}
                                                                                    yAxisLabel={insightsLbls['yAxisLabelCount']}
                                                                                    refId="userEngagementDetail_guideme">
                                                                                </LineChart>
                                                                            )
                                                                        ) : (
                                                                            <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight450">
                                                                                <div className="displayFlex alignCenter margin-0-auto">
                                                                                    <h5 className="errorCss card-heading font-weight-400 text-center">
                                                                                        {userEngagementGuideMeChart.errorMsg}
                                                                                    </h5>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    ) : (
                                                                        <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight450">
                                                                            <div className="displayFlex alignCenter margin-0-auto">
                                                                                <Loader></Loader>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>

                                                                {process.env.REACT_APP_WESTPAC === 'false' && (
                                                                    <div className=" col-sm-12 overflowX chartdiv search_section borderBottom padding0 row marginLR-0">
                                                                        <div className="col-sm-3 col-md-3 col-lg-3 padding0 paddingTop-20 minHeight350">
                                                                            <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                                                                <SectionTooltip tooltipText={
                                                                                    {
                                                                                        "subTitle": insightsLbls.playsByMedia,
                                                                                        "tooltipText": insightsLbls.donutChartShowMeDistribution,
                                                                                        "childern": []
                                                                                    }
                                                                                } placement="right"></SectionTooltip>
                                                                            </ContainerTooltip>
                                                                            <div className="col-sm-12 marginLeft--15 marginTop-20 overflowX chartdiv search_section">

                                                                                {userEngagementShowMeChart.userEngagementShowMeChartDataFetched ? (
                                                                                    errorCount.errorCount[2] === 0 ? (
                                                                                        !userEngagementShowMeChart.showMeDonutDataAvailable ? (
                                                                                            <div className="loaderHeight width100 displayFlex height100-percent top-20 positionRelative minHeight350">
                                                                                                <div className="displayFlex alignCenter margin-0-auto">
                                                                                                    <div className="noDataText-font textCenter-absolute">
                                                                                                        {insightsLbls.noRecordFound}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        ) : (
                                                                                            <Piechart
                                                                                                chartData={getDonutData()}
                                                                                                chartEntities={['guide_slideshow_play', 'guide_video_play', 'guide_gif_play']}
                                                                                                optionEntities={[
                                                                                                    { key: insightsLbls !== undefined ? insightsLbls['slideshow'] : '', color: ChartColors.color1 },
                                                                                                    { key: insightsLbls !== undefined ? insightsLbls['video'] : '', color: ChartColors.color2 },
                                                                                                    { key: insightsLbls !== undefined ? insightsLbls['gif'] : '', color: ChartColors.color3 }
                                                                                                ]}
                                                                                                valueFormat={'value'}
                                                                                                height={300}
                                                                                                width={250}
                                                                                                showLegends={true}
                                                                                                chartType={'donut'}
                                                                                                labelType={'percent'}
                                                                                                pieMargin={pie_margin}>
                                                                                            </Piechart>
                                                                                        )
                                                                                    ) : (
                                                                                        <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight350">
                                                                                            <div className="displayFlex alignCenter margin-0-auto">
                                                                                                <h5 className="errorCss card-heading font-weight-400 text-center">
                                                                                                    {userEngagementShowMeChart.errorMsg}
                                                                                                </h5>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                ) : (
                                                                                    <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight350">
                                                                                        <div className="displayFlex alignCenter margin-0-auto">
                                                                                            <Loader></Loader>
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-sm-6 col-md-6 col-lg-6 borderLR padding0  paddingTop-20 section-wrapper-sides minHeight350">
                                                                            <ContainerTooltip div1Classes={"paddingLeft-15"} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                                                                <SectionTooltip tooltipText={
                                                                                    {
                                                                                        "subTitle": insightsLbls.playsByTime,
                                                                                        "tooltipText": insightsLbls.graphActivitiesOnShowMe,
                                                                                        "childern": [
                                                                                            {
                                                                                                "key": insightsLbls.slideshows + ":",
                                                                                                "value": insightsLbls.slideshowPlayedInGivenTimeFrame
                                                                                            },
                                                                                            {
                                                                                                "key": insightsLbls.videos + ":",
                                                                                                "value": insightsLbls.totalVideosPlayedInGivenTimeFrame
                                                                                            },
                                                                                            {
                                                                                                "key": insightsLbls.gifs + ":",
                                                                                                "value": insightsLbls.totalGIFdPlayedInGivenTimeFrame
                                                                                            }
                                                                                        ]
                                                                                    }
                                                                                } placement="right"></SectionTooltip>
                                                                            </ContainerTooltip>
                                                                            <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section padding0">

                                                                                {userEngagementShowMeChart.userEngagementShowMeChartDataFetched ? (
                                                                                    errorCount.errorCount[2] === 0 ? (
                                                                                        userEngagementShowMeChart.loadShowMeChartData.length === 0 ? (
                                                                                            <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight350">
                                                                                                <div className="displayFlex alignCenter margin-0-auto">
                                                                                                    <div className="noDataText-font textCenter-absolute">
                                                                                                        {insightsLbls.noRecordFound}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        ) : (
                                                                                            <LineChart
                                                                                                chartEntities={['guide_gif_play', 'guide_slideshow_play', 'guide_video_play']}
                                                                                                optionEntities={[
                                                                                                    { key: insightsLbls !== undefined ? insightsLbls['gif'] : '', color: ChartColors.color4, area: true },
                                                                                                    { key: insightsLbls !== undefined ? insightsLbls['slideshow'] : '', color: ChartColors.color3, area: true },
                                                                                                    { key: insightsLbls !== undefined ? insightsLbls['video'] : '', color: ChartColors.color2, area: true },

                                                                                                ]}
                                                                                                chartData={fetched_details?.userEngagementDetails?.userEngagementDetailShowMeChart?.data}
                                                                                                value_type={"count"}
                                                                                                height={guide_linechart_height}
                                                                                                margin={lineChartMargin}
                                                                                                yAxisLabel={insightsLbls['yAxisLabelCount']}
                                                                                                refId="userEngagementDetail_showme">
                                                                                            </LineChart>
                                                                                        )
                                                                                    ) : (
                                                                                        <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight350">
                                                                                            <div className="displayFlex alignCenter margin-0-auto">
                                                                                                <h5 className="errorCss card-heading font-weight-400 text-center">
                                                                                                    {userEngagementShowMeChart.errorMsg}
                                                                                                </h5>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                ) : (
                                                                                    <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight350">
                                                                                        <div className="displayFlex alignCenter margin-0-auto">
                                                                                            <Loader></Loader>
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-sm-3 col-md-3 col-lg-3 padding0 paddingTop-20 minHeight350">
                                                                            <ContainerTooltip div1Classes={"paddingLeft-15"} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                                                                <SectionTooltip tooltipText={
                                                                                    {
                                                                                        "subTitle": insightsLbls.downloads,
                                                                                        "tooltipText": insightsLbls.pieActivityPercentageDistribution,
                                                                                        "childern": []
                                                                                    }
                                                                                } placement="left"></SectionTooltip>
                                                                            </ContainerTooltip>
                                                                            <div className="col-sm-12 marginLeft-10 marginTop-20 overflowX chartdiv search_section">

                                                                                {userEngagementShowMeChart.userEngagementShowMeChartDataFetched ? (
                                                                                    errorCount.errorCount[2] === 0 ? (
                                                                                        !userEngagementShowMeChart.showMePieDataAvailable ? (
                                                                                            <div className="loaderHeight width100 displayFlex height100-percent top-20 positionRelative minHeight350">
                                                                                                <div className="displayFlex alignCenter margin-0-auto">
                                                                                                    <div className="noDataText-font textCenter-absolute">
                                                                                                        {insightsLbls.noRecordFound}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        ) : (
                                                                                            <Piechart
                                                                                                chartData={getPieData()}
                                                                                                chartEntities={['guide_slideshow_download', 'guide_video_download', 'guide_gif_download', 'guide_pdf_download', 'guide_docx_download', 'guide_html_download']}
                                                                                                optionEntities={[
                                                                                                    { key: insightsLbls !== undefined ? insightsLbls['downloadPptx'] : '', color: ChartColors.darkturquoise },
                                                                                                    { key: insightsLbls !== undefined ? insightsLbls['video'] : '', color: ChartColors.brightred },
                                                                                                    { key: insightsLbls !== undefined ? insightsLbls['gif'] : '', color: ChartColors.mediumpurple },
                                                                                                    { key: insightsLbls !== undefined ? insightsLbls['pdf'] : '', color: ChartColors.lightgreen },
                                                                                                    { key: insightsLbls !== undefined ? insightsLbls['docx'] : '', color: ChartColors.yellowsandybrown },
                                                                                                    { key: insightsLbls !== undefined ? insightsLbls['html'] : '', color: ChartColors.orangesandybrown },
                                                                                                ]}
                                                                                                valueFormat={'value'}
                                                                                                height={300}
                                                                                                width={250}
                                                                                                showLegends={true}
                                                                                                chartType={'pie'}
                                                                                                labelType={'percent'}>
                                                                                            </Piechart>
                                                                                        )
                                                                                    ) : (
                                                                                        <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight350">
                                                                                            <div className="displayFlex alignCenter margin-0-auto">
                                                                                                <h5 className="errorCss card-heading font-weight-400 text-center">
                                                                                                    {userEngagementShowMeChart.errorMsg}
                                                                                                </h5>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                ) : (
                                                                                    <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight350">
                                                                                        <div className="displayFlex alignCenter margin-0-auto">
                                                                                            <Loader></Loader>
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                )}


                                                                {process.env.REACT_APP_WESTPAC === 'false' && (
                                                                    <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section borderBottom minHeight450 padding0">
                                                                        <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                                                                            div2Classes={" marginTop-20  icon-text chart-wrapper5 height100-percent"}
                                                                            div3Classes={"cardPadding minHeight450 height100-percent padding0"}>
                                                                            <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                                                                <SectionTooltip tooltipText={
                                                                                    {
                                                                                        "subTitle": insightsLbls.testMeActivity,
                                                                                        "tooltipText": insightsLbls.lineChartOfTestMeActivities,
                                                                                        "childern": []
                                                                                    }
                                                                                } placement="right"></SectionTooltip>
                                                                            </ContainerTooltip>
                                                                            <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section ">

                                                                                {userEngagementTestMeChart.userEngagementTestMeChartDataFetched ? (
                                                                                    errorCount.errorCount[3] === 0 ? (
                                                                                        userEngagementTestMeChart.loadTestMeChartData.length === 0 ? (
                                                                                            <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight450">
                                                                                                {/* <div className="displayFlex alignCenter margin-0-auto">
                                                                                                    <div className="noDataText-font textCenter-absolute">
                                                                                                        {insightsLbls.noRecordFound} 
                                                                                                    </div>
                                                                                                </div> */}
                                                                                            </div>
                                                                                        ) : (
                                                                                            <LineChart
                                                                                                chartEntities={['test_passed', 'test_failed']}
                                                                                                optionEntities={[
                                                                                                    { key: insightsLbls !== undefined ? insightsLbls['passCount'] : '', color: ChartColors.color1, area: true },
                                                                                                    { key: insightsLbls !== undefined ? insightsLbls['failCount'] : '', color: ChartColors.color2, area: true },
                                                                                                ]}
                                                                                                chartData={fetched_details?.userEngagementDetails?.userEngagementDetailTestMeChart?.data}
                                                                                                value_type={"count"}
                                                                                                height={linechart_height}
                                                                                                margin={lineChartMargin}
                                                                                                yAxisLabel={insightsLbls['yAxisLabelCount']}
                                                                                                refId="userEngagementDetail_testme">
                                                                                            </LineChart>
                                                                                        )
                                                                                    ) : (
                                                                                        <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight450">
                                                                                            <div className="displayFlex alignCenter margin-0-auto">
                                                                                                <h5 className="errorCss card-heading font-weight-400 text-center">
                                                                                                    {userEngagementTestMeChart.errorMsg}
                                                                                                </h5>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                ) : (
                                                                                    <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight450">
                                                                                        <div className="displayFlex alignCenter margin-0-auto">
                                                                                            <Loader></Loader>
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </CardContainer>
                                                                    </div>
                                                                )}





                                                                {process.env.REACT_APP_WESTPAC === 'false' && (
                                                                    <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section borderBottom minHeight450 padding0">
                                                                        <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                                                                            div2Classes={" marginTop-20  icon-text chart-wrapper5 height100-percent"}
                                                                            div3Classes={"cardPadding minHeight450 height100-percent padding0"}>
                                                                            <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                                                                <SectionTooltip tooltipText={
                                                                                    {
                                                                                        "subTitle": insightsLbls.doItForMeActivity,
                                                                                        "tooltipText": insightsLbls.graphOfDoItForMeMode,
                                                                                        "childern": [
                                                                                            {
                                                                                                "key": insightsLbls.totalPlayed + ":",
                                                                                                "value": insightsLbls.totalGuidesPlayedInDoItForMeMode
                                                                                            },
                                                                                            {
                                                                                                "key": insightsLbls.completed + ":",
                                                                                                "value": insightsLbls.totalGuidesPlayedFromFirstStep
                                                                                            }
                                                                                        ]
                                                                                    }
                                                                                } placement="right"></SectionTooltip>
                                                                            </ContainerTooltip>
                                                                            <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section ">
                                                                                {
                                                                                    userEngagementDoItForMeChart.userEngagementDoItForMeChartDataFetched ?
                                                                                        (
                                                                                            errorCount.errorCount[4] === 0 ?
                                                                                                (
                                                                                                    userEngagementDoItForMeChart.loadDoItForMeChartData.length === 0 ?
                                                                                                        (
                                                                                                            <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight450">
                                                                                                                <div className="displayFlex alignCenter margin-0-auto">
                                                                                                                    <div className="noDataText-font textCenter-absolute">
                                                                                                                        {insightsLbls.noRecordFound}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        )
                                                                                                        :
                                                                                                        (
                                                                                                            <LineChart
                                                                                                                chartEntities={['doitforme_play', 'doitforme_complete']}
                                                                                                                optionEntities={[
                                                                                                                    { key: insightsLbls !== undefined ? insightsLbls['totalPlayed'] : '', color: ChartColors.color3, area: true },
                                                                                                                    { key: insightsLbls !== undefined ? insightsLbls['completed'] : '', color: ChartColors.color4, area: true }
                                                                                                                ]}
                                                                                                                chartData={fetched_details?.userEngagementDetails?.userEngagementDetailDoItForMeChart?.data}
                                                                                                                value_type={"count"}
                                                                                                                height={linechart_height}
                                                                                                                margin={lineChartMargin}
                                                                                                                yAxisLabel={insightsLbls['yAxisLabelCount']}
                                                                                                                refId="userEngagementDetail_doitforme">
                                                                                                            </LineChart>
                                                                                                        )
                                                                                                )
                                                                                                :
                                                                                                (
                                                                                                    <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight450">
                                                                                                        <div className="displayFlex alignCenter margin-0-auto">
                                                                                                            <h5 className="errorCss card-heading font-weight-400 text-center">
                                                                                                                {userEngagementDoItForMeChart.errorMsg}
                                                                                                            </h5>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                        )
                                                                                        :
                                                                                        (
                                                                                            <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight450">
                                                                                                <div className="displayFlex alignCenter margin-0-auto">
                                                                                                    <Loader></Loader>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                }
                                                                            </div>
                                                                        </CardContainer>
                                                                    </div>
                                                                )}




                                                                <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section  padding0">
                                                                    <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                                                                        div2Classes={" marginTop-20  icon-text chart-wrapper5 borderTop height100-percent"}
                                                                        div3Classes={"cardPadding  height100-percent padding0"}>
                                                                        <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                                                            <SectionTooltip tooltipText={
                                                                                {
                                                                                    "subTitle": insightsLbls.userEngagementTimeline,
                                                                                    "tooltipText": insightsLbls.userActivitiesTimelineCharts,
                                                                                    "childern": [
                                                                                        {
                                                                                            "key": insightsLbls.userEngagementPlayDetails + ":",
                                                                                            "value": insightsLbls.listOfSteps
                                                                                        }
                                                                                    ]
                                                                                }
                                                                            } placement="right"></SectionTooltip>
                                                                        </ContainerTooltip>
                                                                        <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section displayContent">

                                                                            {timelineData.dataFetched ? (
                                                                                errorCount.errorCount[5] === 0 ? (
                                                                                    <Card
                                                                                        cardId="userEngagementDetail"
                                                                                        data={timelineData.dataList}
                                                                                        currentPage={timelineData.page}
                                                                                        cardPagination={timelineData.cardPagination}
                                                                                        clickEvent={handleClick}
                                                                                        showEntries={true}
                                                                                        showEventType={true}
                                                                                        guideTitle={guideDetail.guideName}
                                                                                        firstTimeloadFlag={
                                                                                            timelineData.firstLoad
                                                                                        }>
                                                                                    </Card>
                                                                                ) : (
                                                                                    <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight450">
                                                                                        <div className="displayFlex alignCenter margin-0-auto">
                                                                                            <h5 className="errorCss card-heading font-weight-400 text-center">
                                                                                                {timelineData.errorMsg}
                                                                                            </h5>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            ) : (
                                                                                <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight450">
                                                                                    <div className="displayFlex alignCenter margin-0-auto">
                                                                                        <Loader></Loader>
                                                                                    </div>
                                                                                </div>
                                                                            )}


                                                                        </div>
                                                                    </CardContainer>
                                                                </div>
                                                            </>
                                                        )
                                                        :
                                                        (
                                                            guideDetail.isTutorialGuide !== null && guideDetail.isTutorialGuide === true ?
                                                                (
                                                                    <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section borderBottom minHeight450 padding0">
                                                                        <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                                                                            div2Classes={" marginTop-20  icon-text chart-wrapper5 height100-percent"}
                                                                            div3Classes={"cardPadding minHeight450 height100-percent padding0"}>
                                                                            <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                                                                <SectionTooltip tooltipText={
                                                                                    {
                                                                                        "subTitle": insightsLbls.tutorialGuideActivity,
                                                                                        "tooltipText": insightsLbls.userActivitiesOnTutorialGuide,
                                                                                        "childern": [
                                                                                            {
                                                                                                "key": insightsLbls.totalPlayed + ":",
                                                                                                "value": insightsLbls.totalNumberOfGuideInTutorialGuide
                                                                                            },
                                                                                            {
                                                                                                "key": insightsLbls.completed + ":",
                                                                                                "value": insightsLbls.totalNumberOfGuideInTutorialGuideFromFirstStep
                                                                                            }
                                                                                        ]
                                                                                    }
                                                                                } placement="right"></SectionTooltip>
                                                                            </ContainerTooltip>
                                                                            <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section ">
                                                                                {
                                                                                    userEngagementTutorialGuidesChart.userEngagementTutorialGuidesChartDataFetched ?
                                                                                        (
                                                                                            errorCount.errorCount[6] === 0 ?
                                                                                                (
                                                                                                    userEngagementTutorialGuidesChart.loadTutorialGuidesChartData.length === 0 ?
                                                                                                        (
                                                                                                            <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight450">
                                                                                                                <div className="displayFlex alignCenter margin-0-auto">
                                                                                                                    <div className="noDataText-font textCenter-absolute">
                                                                                                                        {insightsLbls.noRecordFound}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        )
                                                                                                        :
                                                                                                        (
                                                                                                            <LineChart
                                                                                                                chartEntities={['tutorial_play', 'tutorial_complete']}
                                                                                                                optionEntities={[
                                                                                                                    { key: insightsLbls['totalPlayed'], color: ChartColors.color2, area: true },
                                                                                                                    { key: insightsLbls['completed'], color: ChartColors.color3, area: true }
                                                                                                                ]}
                                                                                                                chartData={fetched_details?.userEngagementDetails?.userEngagementDetailTutorialGuidesChart?.data}
                                                                                                                value_type={"count"}
                                                                                                                height={linechart_height}
                                                                                                                margin={lineChartMargin}
                                                                                                                yAxisLabel={insightsLbls['yAxisLabelCount']}
                                                                                                                refId="userEngagementDetail_tutorialGuides">
                                                                                                            </LineChart>
                                                                                                        )
                                                                                                )
                                                                                                :
                                                                                                (
                                                                                                    <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight450">
                                                                                                        <div className="displayFlex alignCenter margin-0-auto">
                                                                                                            <h5 className="errorCss card-heading font-weight-400 text-center">
                                                                                                                {userEngagementTutorialGuidesChart.errorMsg}
                                                                                                            </h5>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                        )
                                                                                        :
                                                                                        (
                                                                                            <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight450">
                                                                                                <div className="displayFlex alignCenter margin-0-auto">
                                                                                                    <Loader></Loader>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                }
                                                                            </div>
                                                                        </CardContainer>
                                                                    </div>
                                                                )
                                                                :
                                                                (null)
                                                        )
                                                }
                                            </CardContainer>
                                        )
                                )
                                :
                                (
                                    <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                                        <div className="displayFlex alignCenter margin-0-auto">
                                            <h5 className="errorCss card-heading font-weight-400 text-center">
                                                {userEngagementTutorialGuidesChart.errorMsg}
                                            </h5>
                                        </div>
                                    </div>
                                )
                        )
                        : (
                            <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight450">
                                <div className="displayFlex alignCenter margin-0-auto">
                                    <Loader></Loader>
                                </div>
                            </div>
                        )
                    }

                </div>
            </div>

            <div className="overlayCursor" id="overlayCursor"></div>
            {errorCount.showMaintenance_flag && (
                <Maintenance></Maintenance>
            )}
        </section>
    );
}

export default withRouter(withReducer("userEngagementDetails", userEngagementDetailReducer)(UserEngagementDetail));
